module.exports = [{
  "value": "#B0171F",
  "name": "indian red"
}, {
  "value": "#DC143C",
  "css": true,
  "name": "crimson"
}, {
  "value": "#FFB6C1",
  "css": true,
  "name": "lightpink"
}, {
  "value": "#FFAEB9",
  "name": "lightpink 1"
}, {
  "value": "#EEA2AD",
  "name": "lightpink 2"
}, {
  "value": "#CD8C95",
  "name": "lightpink 3"
}, {
  "value": "#8B5F65",
  "name": "lightpink 4"
}, {
  "value": "#FFC0CB",
  "css": true,
  "name": "pink"
}, {
  "value": "#FFB5C5",
  "name": "pink 1"
}, {
  "value": "#EEA9B8",
  "name": "pink 2"
}, {
  "value": "#CD919E",
  "name": "pink 3"
}, {
  "value": "#8B636C",
  "name": "pink 4"
}, {
  "value": "#DB7093",
  "css": true,
  "name": "palevioletred"
}, {
  "value": "#FF82AB",
  "name": "palevioletred 1"
}, {
  "value": "#EE799F",
  "name": "palevioletred 2"
}, {
  "value": "#CD6889",
  "name": "palevioletred 3"
}, {
  "value": "#8B475D",
  "name": "palevioletred 4"
}, {
  "value": "#FFF0F5",
  "name": "lavenderblush 1"
}, {
  "value": "#FFF0F5",
  "css": true,
  "name": "lavenderblush"
}, {
  "value": "#EEE0E5",
  "name": "lavenderblush 2"
}, {
  "value": "#CDC1C5",
  "name": "lavenderblush 3"
}, {
  "value": "#8B8386",
  "name": "lavenderblush 4"
}, {
  "value": "#FF3E96",
  "name": "violetred 1"
}, {
  "value": "#EE3A8C",
  "name": "violetred 2"
}, {
  "value": "#CD3278",
  "name": "violetred 3"
}, {
  "value": "#8B2252",
  "name": "violetred 4"
}, {
  "value": "#FF69B4",
  "css": true,
  "name": "hotpink"
}, {
  "value": "#FF6EB4",
  "name": "hotpink 1"
}, {
  "value": "#EE6AA7",
  "name": "hotpink 2"
}, {
  "value": "#CD6090",
  "name": "hotpink 3"
}, {
  "value": "#8B3A62",
  "name": "hotpink 4"
}, {
  "value": "#872657",
  "name": "raspberry"
}, {
  "value": "#FF1493",
  "name": "deeppink 1"
}, {
  "value": "#FF1493",
  "css": true,
  "name": "deeppink"
}, {
  "value": "#EE1289",
  "name": "deeppink 2"
}, {
  "value": "#CD1076",
  "name": "deeppink 3"
}, {
  "value": "#8B0A50",
  "name": "deeppink 4"
}, {
  "value": "#FF34B3",
  "name": "maroon 1"
}, {
  "value": "#EE30A7",
  "name": "maroon 2"
}, {
  "value": "#CD2990",
  "name": "maroon 3"
}, {
  "value": "#8B1C62",
  "name": "maroon 4"
}, {
  "value": "#C71585",
  "css": true,
  "name": "mediumvioletred"
}, {
  "value": "#D02090",
  "name": "violetred"
}, {
  "value": "#DA70D6",
  "css": true,
  "name": "orchid"
}, {
  "value": "#FF83FA",
  "name": "orchid 1"
}, {
  "value": "#EE7AE9",
  "name": "orchid 2"
}, {
  "value": "#CD69C9",
  "name": "orchid 3"
}, {
  "value": "#8B4789",
  "name": "orchid 4"
}, {
  "value": "#D8BFD8",
  "css": true,
  "name": "thistle"
}, {
  "value": "#FFE1FF",
  "name": "thistle 1"
}, {
  "value": "#EED2EE",
  "name": "thistle 2"
}, {
  "value": "#CDB5CD",
  "name": "thistle 3"
}, {
  "value": "#8B7B8B",
  "name": "thistle 4"
}, {
  "value": "#FFBBFF",
  "name": "plum 1"
}, {
  "value": "#EEAEEE",
  "name": "plum 2"
}, {
  "value": "#CD96CD",
  "name": "plum 3"
}, {
  "value": "#8B668B",
  "name": "plum 4"
}, {
  "value": "#DDA0DD",
  "css": true,
  "name": "plum"
}, {
  "value": "#EE82EE",
  "css": true,
  "name": "violet"
}, {
  "value": "#FF00FF",
  "vga": true,
  "name": "magenta"
}, {
  "value": "#FF00FF",
  "vga": true,
  "css": true,
  "name": "fuchsia"
}, {
  "value": "#EE00EE",
  "name": "magenta 2"
}, {
  "value": "#CD00CD",
  "name": "magenta 3"
}, {
  "value": "#8B008B",
  "name": "magenta 4"
}, {
  "value": "#8B008B",
  "css": true,
  "name": "darkmagenta"
}, {
  "value": "#800080",
  "vga": true,
  "css": true,
  "name": "purple"
}, {
  "value": "#BA55D3",
  "css": true,
  "name": "mediumorchid"
}, {
  "value": "#E066FF",
  "name": "mediumorchid 1"
}, {
  "value": "#D15FEE",
  "name": "mediumorchid 2"
}, {
  "value": "#B452CD",
  "name": "mediumorchid 3"
}, {
  "value": "#7A378B",
  "name": "mediumorchid 4"
}, {
  "value": "#9400D3",
  "css": true,
  "name": "darkviolet"
}, {
  "value": "#9932CC",
  "css": true,
  "name": "darkorchid"
}, {
  "value": "#BF3EFF",
  "name": "darkorchid 1"
}, {
  "value": "#B23AEE",
  "name": "darkorchid 2"
}, {
  "value": "#9A32CD",
  "name": "darkorchid 3"
}, {
  "value": "#68228B",
  "name": "darkorchid 4"
}, {
  "value": "#4B0082",
  "css": true,
  "name": "indigo"
}, {
  "value": "#8A2BE2",
  "css": true,
  "name": "blueviolet"
}, {
  "value": "#9B30FF",
  "name": "purple 1"
}, {
  "value": "#912CEE",
  "name": "purple 2"
}, {
  "value": "#7D26CD",
  "name": "purple 3"
}, {
  "value": "#551A8B",
  "name": "purple 4"
}, {
  "value": "#9370DB",
  "css": true,
  "name": "mediumpurple"
}, {
  "value": "#AB82FF",
  "name": "mediumpurple 1"
}, {
  "value": "#9F79EE",
  "name": "mediumpurple 2"
}, {
  "value": "#8968CD",
  "name": "mediumpurple 3"
}, {
  "value": "#5D478B",
  "name": "mediumpurple 4"
}, {
  "value": "#483D8B",
  "css": true,
  "name": "darkslateblue"
}, {
  "value": "#8470FF",
  "name": "lightslateblue"
}, {
  "value": "#7B68EE",
  "css": true,
  "name": "mediumslateblue"
}, {
  "value": "#6A5ACD",
  "css": true,
  "name": "slateblue"
}, {
  "value": "#836FFF",
  "name": "slateblue 1"
}, {
  "value": "#7A67EE",
  "name": "slateblue 2"
}, {
  "value": "#6959CD",
  "name": "slateblue 3"
}, {
  "value": "#473C8B",
  "name": "slateblue 4"
}, {
  "value": "#F8F8FF",
  "css": true,
  "name": "ghostwhite"
}, {
  "value": "#E6E6FA",
  "css": true,
  "name": "lavender"
}, {
  "value": "#0000FF",
  "vga": true,
  "css": true,
  "name": "blue"
}, {
  "value": "#0000EE",
  "name": "blue 2"
}, {
  "value": "#0000CD",
  "name": "blue 3"
}, {
  "value": "#0000CD",
  "css": true,
  "name": "mediumblue"
}, {
  "value": "#00008B",
  "name": "blue 4"
}, {
  "value": "#00008B",
  "css": true,
  "name": "darkblue"
}, {
  "value": "#000080",
  "vga": true,
  "css": true,
  "name": "navy"
}, {
  "value": "#191970",
  "css": true,
  "name": "midnightblue"
}, {
  "value": "#3D59AB",
  "name": "cobalt"
}, {
  "value": "#4169E1",
  "css": true,
  "name": "royalblue"
}, {
  "value": "#4876FF",
  "name": "royalblue 1"
}, {
  "value": "#436EEE",
  "name": "royalblue 2"
}, {
  "value": "#3A5FCD",
  "name": "royalblue 3"
}, {
  "value": "#27408B",
  "name": "royalblue 4"
}, {
  "value": "#6495ED",
  "css": true,
  "name": "cornflowerblue"
}, {
  "value": "#B0C4DE",
  "css": true,
  "name": "lightsteelblue"
}, {
  "value": "#CAE1FF",
  "name": "lightsteelblue 1"
}, {
  "value": "#BCD2EE",
  "name": "lightsteelblue 2"
}, {
  "value": "#A2B5CD",
  "name": "lightsteelblue 3"
}, {
  "value": "#6E7B8B",
  "name": "lightsteelblue 4"
}, {
  "value": "#778899",
  "css": true,
  "name": "lightslategray"
}, {
  "value": "#708090",
  "css": true,
  "name": "slategray"
}, {
  "value": "#C6E2FF",
  "name": "slategray 1"
}, {
  "value": "#B9D3EE",
  "name": "slategray 2"
}, {
  "value": "#9FB6CD",
  "name": "slategray 3"
}, {
  "value": "#6C7B8B",
  "name": "slategray 4"
}, {
  "value": "#1E90FF",
  "name": "dodgerblue 1"
}, {
  "value": "#1E90FF",
  "css": true,
  "name": "dodgerblue"
}, {
  "value": "#1C86EE",
  "name": "dodgerblue 2"
}, {
  "value": "#1874CD",
  "name": "dodgerblue 3"
}, {
  "value": "#104E8B",
  "name": "dodgerblue 4"
}, {
  "value": "#F0F8FF",
  "css": true,
  "name": "aliceblue"
}, {
  "value": "#4682B4",
  "css": true,
  "name": "steelblue"
}, {
  "value": "#63B8FF",
  "name": "steelblue 1"
}, {
  "value": "#5CACEE",
  "name": "steelblue 2"
}, {
  "value": "#4F94CD",
  "name": "steelblue 3"
}, {
  "value": "#36648B",
  "name": "steelblue 4"
}, {
  "value": "#87CEFA",
  "css": true,
  "name": "lightskyblue"
}, {
  "value": "#B0E2FF",
  "name": "lightskyblue 1"
}, {
  "value": "#A4D3EE",
  "name": "lightskyblue 2"
}, {
  "value": "#8DB6CD",
  "name": "lightskyblue 3"
}, {
  "value": "#607B8B",
  "name": "lightskyblue 4"
}, {
  "value": "#87CEFF",
  "name": "skyblue 1"
}, {
  "value": "#7EC0EE",
  "name": "skyblue 2"
}, {
  "value": "#6CA6CD",
  "name": "skyblue 3"
}, {
  "value": "#4A708B",
  "name": "skyblue 4"
}, {
  "value": "#87CEEB",
  "css": true,
  "name": "skyblue"
}, {
  "value": "#00BFFF",
  "name": "deepskyblue 1"
}, {
  "value": "#00BFFF",
  "css": true,
  "name": "deepskyblue"
}, {
  "value": "#00B2EE",
  "name": "deepskyblue 2"
}, {
  "value": "#009ACD",
  "name": "deepskyblue 3"
}, {
  "value": "#00688B",
  "name": "deepskyblue 4"
}, {
  "value": "#33A1C9",
  "name": "peacock"
}, {
  "value": "#ADD8E6",
  "css": true,
  "name": "lightblue"
}, {
  "value": "#BFEFFF",
  "name": "lightblue 1"
}, {
  "value": "#B2DFEE",
  "name": "lightblue 2"
}, {
  "value": "#9AC0CD",
  "name": "lightblue 3"
}, {
  "value": "#68838B",
  "name": "lightblue 4"
}, {
  "value": "#B0E0E6",
  "css": true,
  "name": "powderblue"
}, {
  "value": "#98F5FF",
  "name": "cadetblue 1"
}, {
  "value": "#8EE5EE",
  "name": "cadetblue 2"
}, {
  "value": "#7AC5CD",
  "name": "cadetblue 3"
}, {
  "value": "#53868B",
  "name": "cadetblue 4"
}, {
  "value": "#00F5FF",
  "name": "turquoise 1"
}, {
  "value": "#00E5EE",
  "name": "turquoise 2"
}, {
  "value": "#00C5CD",
  "name": "turquoise 3"
}, {
  "value": "#00868B",
  "name": "turquoise 4"
}, {
  "value": "#5F9EA0",
  "css": true,
  "name": "cadetblue"
}, {
  "value": "#00CED1",
  "css": true,
  "name": "darkturquoise"
}, {
  "value": "#F0FFFF",
  "name": "azure 1"
}, {
  "value": "#F0FFFF",
  "css": true,
  "name": "azure"
}, {
  "value": "#E0EEEE",
  "name": "azure 2"
}, {
  "value": "#C1CDCD",
  "name": "azure 3"
}, {
  "value": "#838B8B",
  "name": "azure 4"
}, {
  "value": "#E0FFFF",
  "name": "lightcyan 1"
}, {
  "value": "#E0FFFF",
  "css": true,
  "name": "lightcyan"
}, {
  "value": "#D1EEEE",
  "name": "lightcyan 2"
}, {
  "value": "#B4CDCD",
  "name": "lightcyan 3"
}, {
  "value": "#7A8B8B",
  "name": "lightcyan 4"
}, {
  "value": "#BBFFFF",
  "name": "paleturquoise 1"
}, {
  "value": "#AEEEEE",
  "name": "paleturquoise 2"
}, {
  "value": "#AEEEEE",
  "css": true,
  "name": "paleturquoise"
}, {
  "value": "#96CDCD",
  "name": "paleturquoise 3"
}, {
  "value": "#668B8B",
  "name": "paleturquoise 4"
}, {
  "value": "#2F4F4F",
  "css": true,
  "name": "darkslategray"
}, {
  "value": "#97FFFF",
  "name": "darkslategray 1"
}, {
  "value": "#8DEEEE",
  "name": "darkslategray 2"
}, {
  "value": "#79CDCD",
  "name": "darkslategray 3"
}, {
  "value": "#528B8B",
  "name": "darkslategray 4"
}, {
  "value": "#00FFFF",
  "name": "cyan"
}, {
  "value": "#00FFFF",
  "css": true,
  "name": "aqua"
}, {
  "value": "#00EEEE",
  "name": "cyan 2"
}, {
  "value": "#00CDCD",
  "name": "cyan 3"
}, {
  "value": "#008B8B",
  "name": "cyan 4"
}, {
  "value": "#008B8B",
  "css": true,
  "name": "darkcyan"
}, {
  "value": "#008080",
  "vga": true,
  "css": true,
  "name": "teal"
}, {
  "value": "#48D1CC",
  "css": true,
  "name": "mediumturquoise"
}, {
  "value": "#20B2AA",
  "css": true,
  "name": "lightseagreen"
}, {
  "value": "#03A89E",
  "name": "manganeseblue"
}, {
  "value": "#40E0D0",
  "css": true,
  "name": "turquoise"
}, {
  "value": "#808A87",
  "name": "coldgrey"
}, {
  "value": "#00C78C",
  "name": "turquoiseblue"
}, {
  "value": "#7FFFD4",
  "name": "aquamarine 1"
}, {
  "value": "#7FFFD4",
  "css": true,
  "name": "aquamarine"
}, {
  "value": "#76EEC6",
  "name": "aquamarine 2"
}, {
  "value": "#66CDAA",
  "name": "aquamarine 3"
}, {
  "value": "#66CDAA",
  "css": true,
  "name": "mediumaquamarine"
}, {
  "value": "#458B74",
  "name": "aquamarine 4"
}, {
  "value": "#00FA9A",
  "css": true,
  "name": "mediumspringgreen"
}, {
  "value": "#F5FFFA",
  "css": true,
  "name": "mintcream"
}, {
  "value": "#00FF7F",
  "css": true,
  "name": "springgreen"
}, {
  "value": "#00EE76",
  "name": "springgreen 1"
}, {
  "value": "#00CD66",
  "name": "springgreen 2"
}, {
  "value": "#008B45",
  "name": "springgreen 3"
}, {
  "value": "#3CB371",
  "css": true,
  "name": "mediumseagreen"
}, {
  "value": "#54FF9F",
  "name": "seagreen 1"
}, {
  "value": "#4EEE94",
  "name": "seagreen 2"
}, {
  "value": "#43CD80",
  "name": "seagreen 3"
}, {
  "value": "#2E8B57",
  "name": "seagreen 4"
}, {
  "value": "#2E8B57",
  "css": true,
  "name": "seagreen"
}, {
  "value": "#00C957",
  "name": "emeraldgreen"
}, {
  "value": "#BDFCC9",
  "name": "mint"
}, {
  "value": "#3D9140",
  "name": "cobaltgreen"
}, {
  "value": "#F0FFF0",
  "name": "honeydew 1"
}, {
  "value": "#F0FFF0",
  "css": true,
  "name": "honeydew"
}, {
  "value": "#E0EEE0",
  "name": "honeydew 2"
}, {
  "value": "#C1CDC1",
  "name": "honeydew 3"
}, {
  "value": "#838B83",
  "name": "honeydew 4"
}, {
  "value": "#8FBC8F",
  "css": true,
  "name": "darkseagreen"
}, {
  "value": "#C1FFC1",
  "name": "darkseagreen 1"
}, {
  "value": "#B4EEB4",
  "name": "darkseagreen 2"
}, {
  "value": "#9BCD9B",
  "name": "darkseagreen 3"
}, {
  "value": "#698B69",
  "name": "darkseagreen 4"
}, {
  "value": "#98FB98",
  "css": true,
  "name": "palegreen"
}, {
  "value": "#9AFF9A",
  "name": "palegreen 1"
}, {
  "value": "#90EE90",
  "name": "palegreen 2"
}, {
  "value": "#90EE90",
  "css": true,
  "name": "lightgreen"
}, {
  "value": "#7CCD7C",
  "name": "palegreen 3"
}, {
  "value": "#548B54",
  "name": "palegreen 4"
}, {
  "value": "#32CD32",
  "css": true,
  "name": "limegreen"
}, {
  "value": "#228B22",
  "css": true,
  "name": "forestgreen"
}, {
  "value": "#00FF00",
  "vga": true,
  "name": "green 1"
}, {
  "value": "#00FF00",
  "vga": true,
  "css": true,
  "name": "lime"
}, {
  "value": "#00EE00",
  "name": "green 2"
}, {
  "value": "#00CD00",
  "name": "green 3"
}, {
  "value": "#008B00",
  "name": "green 4"
}, {
  "value": "#008000",
  "vga": true,
  "css": true,
  "name": "green"
}, {
  "value": "#006400",
  "css": true,
  "name": "darkgreen"
}, {
  "value": "#308014",
  "name": "sapgreen"
}, {
  "value": "#7CFC00",
  "css": true,
  "name": "lawngreen"
}, {
  "value": "#7FFF00",
  "name": "chartreuse 1"
}, {
  "value": "#7FFF00",
  "css": true,
  "name": "chartreuse"
}, {
  "value": "#76EE00",
  "name": "chartreuse 2"
}, {
  "value": "#66CD00",
  "name": "chartreuse 3"
}, {
  "value": "#458B00",
  "name": "chartreuse 4"
}, {
  "value": "#ADFF2F",
  "css": true,
  "name": "greenyellow"
}, {
  "value": "#CAFF70",
  "name": "darkolivegreen 1"
}, {
  "value": "#BCEE68",
  "name": "darkolivegreen 2"
}, {
  "value": "#A2CD5A",
  "name": "darkolivegreen 3"
}, {
  "value": "#6E8B3D",
  "name": "darkolivegreen 4"
}, {
  "value": "#556B2F",
  "css": true,
  "name": "darkolivegreen"
}, {
  "value": "#6B8E23",
  "css": true,
  "name": "olivedrab"
}, {
  "value": "#C0FF3E",
  "name": "olivedrab 1"
}, {
  "value": "#B3EE3A",
  "name": "olivedrab 2"
}, {
  "value": "#9ACD32",
  "name": "olivedrab 3"
}, {
  "value": "#9ACD32",
  "css": true,
  "name": "yellowgreen"
}, {
  "value": "#698B22",
  "name": "olivedrab 4"
}, {
  "value": "#FFFFF0",
  "name": "ivory 1"
}, {
  "value": "#FFFFF0",
  "css": true,
  "name": "ivory"
}, {
  "value": "#EEEEE0",
  "name": "ivory 2"
}, {
  "value": "#CDCDC1",
  "name": "ivory 3"
}, {
  "value": "#8B8B83",
  "name": "ivory 4"
}, {
  "value": "#F5F5DC",
  "css": true,
  "name": "beige"
}, {
  "value": "#FFFFE0",
  "name": "lightyellow 1"
}, {
  "value": "#FFFFE0",
  "css": true,
  "name": "lightyellow"
}, {
  "value": "#EEEED1",
  "name": "lightyellow 2"
}, {
  "value": "#CDCDB4",
  "name": "lightyellow 3"
}, {
  "value": "#8B8B7A",
  "name": "lightyellow 4"
}, {
  "value": "#FAFAD2",
  "css": true,
  "name": "lightgoldenrodyellow"
}, {
  "value": "#FFFF00",
  "vga": true,
  "name": "yellow 1"
}, {
  "value": "#FFFF00",
  "vga": true,
  "css": true,
  "name": "yellow"
}, {
  "value": "#EEEE00",
  "name": "yellow 2"
}, {
  "value": "#CDCD00",
  "name": "yellow 3"
}, {
  "value": "#8B8B00",
  "name": "yellow 4"
}, {
  "value": "#808069",
  "name": "warmgrey"
}, {
  "value": "#808000",
  "vga": true,
  "css": true,
  "name": "olive"
}, {
  "value": "#BDB76B",
  "css": true,
  "name": "darkkhaki"
}, {
  "value": "#FFF68F",
  "name": "khaki 1"
}, {
  "value": "#EEE685",
  "name": "khaki 2"
}, {
  "value": "#CDC673",
  "name": "khaki 3"
}, {
  "value": "#8B864E",
  "name": "khaki 4"
}, {
  "value": "#F0E68C",
  "css": true,
  "name": "khaki"
}, {
  "value": "#EEE8AA",
  "css": true,
  "name": "palegoldenrod"
}, {
  "value": "#FFFACD",
  "name": "lemonchiffon 1"
}, {
  "value": "#FFFACD",
  "css": true,
  "name": "lemonchiffon"
}, {
  "value": "#EEE9BF",
  "name": "lemonchiffon 2"
}, {
  "value": "#CDC9A5",
  "name": "lemonchiffon 3"
}, {
  "value": "#8B8970",
  "name": "lemonchiffon 4"
}, {
  "value": "#FFEC8B",
  "name": "lightgoldenrod 1"
}, {
  "value": "#EEDC82",
  "name": "lightgoldenrod 2"
}, {
  "value": "#CDBE70",
  "name": "lightgoldenrod 3"
}, {
  "value": "#8B814C",
  "name": "lightgoldenrod 4"
}, {
  "value": "#E3CF57",
  "name": "banana"
}, {
  "value": "#FFD700",
  "name": "gold 1"
}, {
  "value": "#FFD700",
  "css": true,
  "name": "gold"
}, {
  "value": "#EEC900",
  "name": "gold 2"
}, {
  "value": "#CDAD00",
  "name": "gold 3"
}, {
  "value": "#8B7500",
  "name": "gold 4"
}, {
  "value": "#FFF8DC",
  "name": "cornsilk 1"
}, {
  "value": "#FFF8DC",
  "css": true,
  "name": "cornsilk"
}, {
  "value": "#EEE8CD",
  "name": "cornsilk 2"
}, {
  "value": "#CDC8B1",
  "name": "cornsilk 3"
}, {
  "value": "#8B8878",
  "name": "cornsilk 4"
}, {
  "value": "#DAA520",
  "css": true,
  "name": "goldenrod"
}, {
  "value": "#FFC125",
  "name": "goldenrod 1"
}, {
  "value": "#EEB422",
  "name": "goldenrod 2"
}, {
  "value": "#CD9B1D",
  "name": "goldenrod 3"
}, {
  "value": "#8B6914",
  "name": "goldenrod 4"
}, {
  "value": "#B8860B",
  "css": true,
  "name": "darkgoldenrod"
}, {
  "value": "#FFB90F",
  "name": "darkgoldenrod 1"
}, {
  "value": "#EEAD0E",
  "name": "darkgoldenrod 2"
}, {
  "value": "#CD950C",
  "name": "darkgoldenrod 3"
}, {
  "value": "#8B6508",
  "name": "darkgoldenrod 4"
}, {
  "value": "#FFA500",
  "name": "orange 1"
}, {
  "value": "#FF8000",
  "css": true,
  "name": "orange"
}, {
  "value": "#EE9A00",
  "name": "orange 2"
}, {
  "value": "#CD8500",
  "name": "orange 3"
}, {
  "value": "#8B5A00",
  "name": "orange 4"
}, {
  "value": "#FFFAF0",
  "css": true,
  "name": "floralwhite"
}, {
  "value": "#FDF5E6",
  "css": true,
  "name": "oldlace"
}, {
  "value": "#F5DEB3",
  "css": true,
  "name": "wheat"
}, {
  "value": "#FFE7BA",
  "name": "wheat 1"
}, {
  "value": "#EED8AE",
  "name": "wheat 2"
}, {
  "value": "#CDBA96",
  "name": "wheat 3"
}, {
  "value": "#8B7E66",
  "name": "wheat 4"
}, {
  "value": "#FFE4B5",
  "css": true,
  "name": "moccasin"
}, {
  "value": "#FFEFD5",
  "css": true,
  "name": "papayawhip"
}, {
  "value": "#FFEBCD",
  "css": true,
  "name": "blanchedalmond"
}, {
  "value": "#FFDEAD",
  "name": "navajowhite 1"
}, {
  "value": "#FFDEAD",
  "css": true,
  "name": "navajowhite"
}, {
  "value": "#EECFA1",
  "name": "navajowhite 2"
}, {
  "value": "#CDB38B",
  "name": "navajowhite 3"
}, {
  "value": "#8B795E",
  "name": "navajowhite 4"
}, {
  "value": "#FCE6C9",
  "name": "eggshell"
}, {
  "value": "#D2B48C",
  "css": true,
  "name": "tan"
}, {
  "value": "#9C661F",
  "name": "brick"
}, {
  "value": "#FF9912",
  "name": "cadmiumyellow"
}, {
  "value": "#FAEBD7",
  "css": true,
  "name": "antiquewhite"
}, {
  "value": "#FFEFDB",
  "name": "antiquewhite 1"
}, {
  "value": "#EEDFCC",
  "name": "antiquewhite 2"
}, {
  "value": "#CDC0B0",
  "name": "antiquewhite 3"
}, {
  "value": "#8B8378",
  "name": "antiquewhite 4"
}, {
  "value": "#DEB887",
  "css": true,
  "name": "burlywood"
}, {
  "value": "#FFD39B",
  "name": "burlywood 1"
}, {
  "value": "#EEC591",
  "name": "burlywood 2"
}, {
  "value": "#CDAA7D",
  "name": "burlywood 3"
}, {
  "value": "#8B7355",
  "name": "burlywood 4"
}, {
  "value": "#FFE4C4",
  "name": "bisque 1"
}, {
  "value": "#FFE4C4",
  "css": true,
  "name": "bisque"
}, {
  "value": "#EED5B7",
  "name": "bisque 2"
}, {
  "value": "#CDB79E",
  "name": "bisque 3"
}, {
  "value": "#8B7D6B",
  "name": "bisque 4"
}, {
  "value": "#E3A869",
  "name": "melon"
}, {
  "value": "#ED9121",
  "name": "carrot"
}, {
  "value": "#FF8C00",
  "css": true,
  "name": "darkorange"
}, {
  "value": "#FF7F00",
  "name": "darkorange 1"
}, {
  "value": "#EE7600",
  "name": "darkorange 2"
}, {
  "value": "#CD6600",
  "name": "darkorange 3"
}, {
  "value": "#8B4500",
  "name": "darkorange 4"
}, {
  "value": "#FFA54F",
  "name": "tan 1"
}, {
  "value": "#EE9A49",
  "name": "tan 2"
}, {
  "value": "#CD853F",
  "name": "tan 3"
}, {
  "value": "#CD853F",
  "css": true,
  "name": "peru"
}, {
  "value": "#8B5A2B",
  "name": "tan 4"
}, {
  "value": "#FAF0E6",
  "css": true,
  "name": "linen"
}, {
  "value": "#FFDAB9",
  "name": "peachpuff 1"
}, {
  "value": "#FFDAB9",
  "css": true,
  "name": "peachpuff"
}, {
  "value": "#EECBAD",
  "name": "peachpuff 2"
}, {
  "value": "#CDAF95",
  "name": "peachpuff 3"
}, {
  "value": "#8B7765",
  "name": "peachpuff 4"
}, {
  "value": "#FFF5EE",
  "name": "seashell 1"
}, {
  "value": "#FFF5EE",
  "css": true,
  "name": "seashell"
}, {
  "value": "#EEE5DE",
  "name": "seashell 2"
}, {
  "value": "#CDC5BF",
  "name": "seashell 3"
}, {
  "value": "#8B8682",
  "name": "seashell 4"
}, {
  "value": "#F4A460",
  "css": true,
  "name": "sandybrown"
}, {
  "value": "#C76114",
  "name": "rawsienna"
}, {
  "value": "#D2691E",
  "css": true,
  "name": "chocolate"
}, {
  "value": "#FF7F24",
  "name": "chocolate 1"
}, {
  "value": "#EE7621",
  "name": "chocolate 2"
}, {
  "value": "#CD661D",
  "name": "chocolate 3"
}, {
  "value": "#8B4513",
  "name": "chocolate 4"
}, {
  "value": "#8B4513",
  "css": true,
  "name": "saddlebrown"
}, {
  "value": "#292421",
  "name": "ivoryblack"
}, {
  "value": "#FF7D40",
  "name": "flesh"
}, {
  "value": "#FF6103",
  "name": "cadmiumorange"
}, {
  "value": "#8A360F",
  "name": "burntsienna"
}, {
  "value": "#A0522D",
  "css": true,
  "name": "sienna"
}, {
  "value": "#FF8247",
  "name": "sienna 1"
}, {
  "value": "#EE7942",
  "name": "sienna 2"
}, {
  "value": "#CD6839",
  "name": "sienna 3"
}, {
  "value": "#8B4726",
  "name": "sienna 4"
}, {
  "value": "#FFA07A",
  "name": "lightsalmon 1"
}, {
  "value": "#FFA07A",
  "css": true,
  "name": "lightsalmon"
}, {
  "value": "#EE9572",
  "name": "lightsalmon 2"
}, {
  "value": "#CD8162",
  "name": "lightsalmon 3"
}, {
  "value": "#8B5742",
  "name": "lightsalmon 4"
}, {
  "value": "#FF7F50",
  "css": true,
  "name": "coral"
}, {
  "value": "#FF4500",
  "name": "orangered 1"
}, {
  "value": "#FF4500",
  "css": true,
  "name": "orangered"
}, {
  "value": "#EE4000",
  "name": "orangered 2"
}, {
  "value": "#CD3700",
  "name": "orangered 3"
}, {
  "value": "#8B2500",
  "name": "orangered 4"
}, {
  "value": "#5E2612",
  "name": "sepia"
}, {
  "value": "#E9967A",
  "css": true,
  "name": "darksalmon"
}, {
  "value": "#FF8C69",
  "name": "salmon 1"
}, {
  "value": "#EE8262",
  "name": "salmon 2"
}, {
  "value": "#CD7054",
  "name": "salmon 3"
}, {
  "value": "#8B4C39",
  "name": "salmon 4"
}, {
  "value": "#FF7256",
  "name": "coral 1"
}, {
  "value": "#EE6A50",
  "name": "coral 2"
}, {
  "value": "#CD5B45",
  "name": "coral 3"
}, {
  "value": "#8B3E2F",
  "name": "coral 4"
}, {
  "value": "#8A3324",
  "name": "burntumber"
}, {
  "value": "#FF6347",
  "name": "tomato 1"
}, {
  "value": "#FF6347",
  "css": true,
  "name": "tomato"
}, {
  "value": "#EE5C42",
  "name": "tomato 2"
}, {
  "value": "#CD4F39",
  "name": "tomato 3"
}, {
  "value": "#8B3626",
  "name": "tomato 4"
}, {
  "value": "#FA8072",
  "css": true,
  "name": "salmon"
}, {
  "value": "#FFE4E1",
  "name": "mistyrose 1"
}, {
  "value": "#FFE4E1",
  "css": true,
  "name": "mistyrose"
}, {
  "value": "#EED5D2",
  "name": "mistyrose 2"
}, {
  "value": "#CDB7B5",
  "name": "mistyrose 3"
}, {
  "value": "#8B7D7B",
  "name": "mistyrose 4"
}, {
  "value": "#FFFAFA",
  "name": "snow 1"
}, {
  "value": "#FFFAFA",
  "css": true,
  "name": "snow"
}, {
  "value": "#EEE9E9",
  "name": "snow 2"
}, {
  "value": "#CDC9C9",
  "name": "snow 3"
}, {
  "value": "#8B8989",
  "name": "snow 4"
}, {
  "value": "#BC8F8F",
  "css": true,
  "name": "rosybrown"
}, {
  "value": "#FFC1C1",
  "name": "rosybrown 1"
}, {
  "value": "#EEB4B4",
  "name": "rosybrown 2"
}, {
  "value": "#CD9B9B",
  "name": "rosybrown 3"
}, {
  "value": "#8B6969",
  "name": "rosybrown 4"
}, {
  "value": "#F08080",
  "css": true,
  "name": "lightcoral"
}, {
  "value": "#CD5C5C",
  "css": true,
  "name": "indianred"
}, {
  "value": "#FF6A6A",
  "name": "indianred 1"
}, {
  "value": "#EE6363",
  "name": "indianred 2"
}, {
  "value": "#8B3A3A",
  "name": "indianred 4"
}, {
  "value": "#CD5555",
  "name": "indianred 3"
}, {
  "value": "#A52A2A",
  "css": true,
  "name": "brown"
}, {
  "value": "#FF4040",
  "name": "brown 1"
}, {
  "value": "#EE3B3B",
  "name": "brown 2"
}, {
  "value": "#CD3333",
  "name": "brown 3"
}, {
  "value": "#8B2323",
  "name": "brown 4"
}, {
  "value": "#B22222",
  "css": true,
  "name": "firebrick"
}, {
  "value": "#FF3030",
  "name": "firebrick 1"
}, {
  "value": "#EE2C2C",
  "name": "firebrick 2"
}, {
  "value": "#CD2626",
  "name": "firebrick 3"
}, {
  "value": "#8B1A1A",
  "name": "firebrick 4"
}, {
  "value": "#FF0000",
  "vga": true,
  "name": "red 1"
}, {
  "value": "#FF0000",
  "vga": true,
  "css": true,
  "name": "red"
}, {
  "value": "#EE0000",
  "name": "red 2"
}, {
  "value": "#CD0000",
  "name": "red 3"
}, {
  "value": "#8B0000",
  "name": "red 4"
}, {
  "value": "#8B0000",
  "css": true,
  "name": "darkred"
}, {
  "value": "#800000",
  "vga": true,
  "css": true,
  "name": "maroon"
}, {
  "value": "#8E388E",
  "name": "sgi beet"
}, {
  "value": "#7171C6",
  "name": "sgi slateblue"
}, {
  "value": "#7D9EC0",
  "name": "sgi lightblue"
}, {
  "value": "#388E8E",
  "name": "sgi teal"
}, {
  "value": "#71C671",
  "name": "sgi chartreuse"
}, {
  "value": "#8E8E38",
  "name": "sgi olivedrab"
}, {
  "value": "#C5C1AA",
  "name": "sgi brightgray"
}, {
  "value": "#C67171",
  "name": "sgi salmon"
}, {
  "value": "#555555",
  "name": "sgi darkgray"
}, {
  "value": "#1E1E1E",
  "name": "sgi gray 12"
}, {
  "value": "#282828",
  "name": "sgi gray 16"
}, {
  "value": "#515151",
  "name": "sgi gray 32"
}, {
  "value": "#5B5B5B",
  "name": "sgi gray 36"
}, {
  "value": "#848484",
  "name": "sgi gray 52"
}, {
  "value": "#8E8E8E",
  "name": "sgi gray 56"
}, {
  "value": "#AAAAAA",
  "name": "sgi lightgray"
}, {
  "value": "#B7B7B7",
  "name": "sgi gray 72"
}, {
  "value": "#C1C1C1",
  "name": "sgi gray 76"
}, {
  "value": "#EAEAEA",
  "name": "sgi gray 92"
}, {
  "value": "#F4F4F4",
  "name": "sgi gray 96"
}, {
  "value": "#FFFFFF",
  "vga": true,
  "css": true,
  "name": "white"
}, {
  "value": "#F5F5F5",
  "name": "white smoke"
}, {
  "value": "#F5F5F5",
  "name": "gray 96"
}, {
  "value": "#DCDCDC",
  "css": true,
  "name": "gainsboro"
}, {
  "value": "#D3D3D3",
  "css": true,
  "name": "lightgrey"
}, {
  "value": "#C0C0C0",
  "vga": true,
  "css": true,
  "name": "silver"
}, {
  "value": "#A9A9A9",
  "css": true,
  "name": "darkgray"
}, {
  "value": "#808080",
  "vga": true,
  "css": true,
  "name": "gray"
}, {
  "value": "#696969",
  "css": true,
  "name": "dimgray"
}, {
  "value": "#696969",
  "name": "gray 42"
}, {
  "value": "#000000",
  "vga": true,
  "css": true,
  "name": "black"
}, {
  "value": "#FCFCFC",
  "name": "gray 99"
}, {
  "value": "#FAFAFA",
  "name": "gray 98"
}, {
  "value": "#F7F7F7",
  "name": "gray 97"
}, {
  "value": "#F2F2F2",
  "name": "gray 95"
}, {
  "value": "#F0F0F0",
  "name": "gray 94"
}, {
  "value": "#EDEDED",
  "name": "gray 93"
}, {
  "value": "#EBEBEB",
  "name": "gray 92"
}, {
  "value": "#E8E8E8",
  "name": "gray 91"
}, {
  "value": "#E5E5E5",
  "name": "gray 90"
}, {
  "value": "#E3E3E3",
  "name": "gray 89"
}, {
  "value": "#E0E0E0",
  "name": "gray 88"
}, {
  "value": "#DEDEDE",
  "name": "gray 87"
}, {
  "value": "#DBDBDB",
  "name": "gray 86"
}, {
  "value": "#D9D9D9",
  "name": "gray 85"
}, {
  "value": "#D6D6D6",
  "name": "gray 84"
}, {
  "value": "#D4D4D4",
  "name": "gray 83"
}, {
  "value": "#D1D1D1",
  "name": "gray 82"
}, {
  "value": "#CFCFCF",
  "name": "gray 81"
}, {
  "value": "#CCCCCC",
  "name": "gray 80"
}, {
  "value": "#C9C9C9",
  "name": "gray 79"
}, {
  "value": "#C7C7C7",
  "name": "gray 78"
}, {
  "value": "#C4C4C4",
  "name": "gray 77"
}, {
  "value": "#C2C2C2",
  "name": "gray 76"
}, {
  "value": "#BFBFBF",
  "name": "gray 75"
}, {
  "value": "#BDBDBD",
  "name": "gray 74"
}, {
  "value": "#BABABA",
  "name": "gray 73"
}, {
  "value": "#B8B8B8",
  "name": "gray 72"
}, {
  "value": "#B5B5B5",
  "name": "gray 71"
}, {
  "value": "#B3B3B3",
  "name": "gray 70"
}, {
  "value": "#B0B0B0",
  "name": "gray 69"
}, {
  "value": "#ADADAD",
  "name": "gray 68"
}, {
  "value": "#ABABAB",
  "name": "gray 67"
}, {
  "value": "#A8A8A8",
  "name": "gray 66"
}, {
  "value": "#A6A6A6",
  "name": "gray 65"
}, {
  "value": "#A3A3A3",
  "name": "gray 64"
}, {
  "value": "#A1A1A1",
  "name": "gray 63"
}, {
  "value": "#9E9E9E",
  "name": "gray 62"
}, {
  "value": "#9C9C9C",
  "name": "gray 61"
}, {
  "value": "#999999",
  "name": "gray 60"
}, {
  "value": "#969696",
  "name": "gray 59"
}, {
  "value": "#949494",
  "name": "gray 58"
}, {
  "value": "#919191",
  "name": "gray 57"
}, {
  "value": "#8F8F8F",
  "name": "gray 56"
}, {
  "value": "#8C8C8C",
  "name": "gray 55"
}, {
  "value": "#8A8A8A",
  "name": "gray 54"
}, {
  "value": "#878787",
  "name": "gray 53"
}, {
  "value": "#858585",
  "name": "gray 52"
}, {
  "value": "#828282",
  "name": "gray 51"
}, {
  "value": "#7F7F7F",
  "name": "gray 50"
}, {
  "value": "#7D7D7D",
  "name": "gray 49"
}, {
  "value": "#7A7A7A",
  "name": "gray 48"
}, {
  "value": "#787878",
  "name": "gray 47"
}, {
  "value": "#757575",
  "name": "gray 46"
}, {
  "value": "#737373",
  "name": "gray 45"
}, {
  "value": "#707070",
  "name": "gray 44"
}, {
  "value": "#6E6E6E",
  "name": "gray 43"
}, {
  "value": "#666666",
  "name": "gray 40"
}, {
  "value": "#636363",
  "name": "gray 39"
}, {
  "value": "#616161",
  "name": "gray 38"
}, {
  "value": "#5E5E5E",
  "name": "gray 37"
}, {
  "value": "#5C5C5C",
  "name": "gray 36"
}, {
  "value": "#595959",
  "name": "gray 35"
}, {
  "value": "#575757",
  "name": "gray 34"
}, {
  "value": "#545454",
  "name": "gray 33"
}, {
  "value": "#525252",
  "name": "gray 32"
}, {
  "value": "#4F4F4F",
  "name": "gray 31"
}, {
  "value": "#4D4D4D",
  "name": "gray 30"
}, {
  "value": "#4A4A4A",
  "name": "gray 29"
}, {
  "value": "#474747",
  "name": "gray 28"
}, {
  "value": "#454545",
  "name": "gray 27"
}, {
  "value": "#424242",
  "name": "gray 26"
}, {
  "value": "#404040",
  "name": "gray 25"
}, {
  "value": "#3D3D3D",
  "name": "gray 24"
}, {
  "value": "#3B3B3B",
  "name": "gray 23"
}, {
  "value": "#383838",
  "name": "gray 22"
}, {
  "value": "#363636",
  "name": "gray 21"
}, {
  "value": "#333333",
  "name": "gray 20"
}, {
  "value": "#303030",
  "name": "gray 19"
}, {
  "value": "#2E2E2E",
  "name": "gray 18"
}, {
  "value": "#2B2B2B",
  "name": "gray 17"
}, {
  "value": "#292929",
  "name": "gray 16"
}, {
  "value": "#262626",
  "name": "gray 15"
}, {
  "value": "#242424",
  "name": "gray 14"
}, {
  "value": "#212121",
  "name": "gray 13"
}, {
  "value": "#1F1F1F",
  "name": "gray 12"
}, {
  "value": "#1C1C1C",
  "name": "gray 11"
}, {
  "value": "#1A1A1A",
  "name": "gray 10"
}, {
  "value": "#171717",
  "name": "gray 9"
}, {
  "value": "#141414",
  "name": "gray 8"
}, {
  "value": "#121212",
  "name": "gray 7"
}, {
  "value": "#0F0F0F",
  "name": "gray 6"
}, {
  "value": "#0D0D0D",
  "name": "gray 5"
}, {
  "value": "#0A0A0A",
  "name": "gray 4"
}, {
  "value": "#080808",
  "name": "gray 3"
}, {
  "value": "#050505",
  "name": "gray 2"
}, {
  "value": "#030303",
  "name": "gray 1"
}, {
  "value": "#F5F5F5",
  "css": true,
  "name": "whitesmoke"
}];