<div
  class="color-picker-wrapper"
  [class.light-theme]="props?.theme === 'light'"
  [style.top.px]="props?.top"
  [style.left.px]="props?.left">
  <div
    *ngIf="props?.alpha; else noAlpha"
    [(colorPicker)]="defaultColor"
    [(cpToggle)]="toggle"
    [cpOKButton]="true"
    [cpCancelButton]="true"
    [cpSaveClickOutside]="false"
    [cpOKButtonClass]="'btn btn-cml-primary btn-xs'"
    [cpCancelButtonClass]="'btn btn-cml-primary btn-xs me-1'"
    [cpAlphaChannel]="'forced'"
    [cpDisableInput]="true"
    [cpPresetColors]="alphaPresetColors"
    (colorPickerSelect)="selectColor($event)">
  </div>
  <ng-template #noAlpha>
  <div
    [(colorPicker)]="defaultColor"
    [(cpToggle)]="toggle"
    [cpOKButton]="true"
    [cpCancelButton]="true"
    [cpSaveClickOutside]="false"
    [cpOKButtonClass]="'btn btn-cml-primary btn-xs'"
    [cpCancelButtonClass]="'btn btn-cml-primary btn-xs m-3'"
    [cpAlphaChannel]="'disabled'"
    [cpDisableInput]="true"
    [cpPresetColors]="presetColors"
    (colorPickerSelect)="selectColor($event)">
  </div>
  </ng-template>
</div>
